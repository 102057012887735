/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-literals */
import React from 'react';
import styles from './menu.css';
import './menu.css';

import Dashboard from '../../lib/assets/dashboard.svg';
import BlockSVG from '../../lib/assets/block.svg';
import LibrarySVG from '../../lib/assets/library.svg';
import TextSVG from '../../lib/assets/text.svg';
import LeaderboardSVG from '../../lib/assets/leaderboard.svg';
import ClassSVG from '../../lib/assets/class.svg';
import SettingsSVG from '../../lib/assets/settings.svg';
import LogoutSVG from '../../lib/assets/logout.svg';
import KeyboardSVG from '../../lib/assets/keyboard.svg';

const Navbar = () => {
    console.log(process.env);
    return (
        <nav className={styles.navbar}>
            <ul className={styles.navigationMenu}>
                <li>
                    <a href={`https://app.techne.nz/dashboard`}>
                        <img src={Dashboard} />
                        <span>Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href={`https://app.techne.nz/library`}>
                        <img src={LibrarySVG} />

                        <span>Library</span>
                    </a>
                </li>
                <li>
                    <a
                        href={`https://app.techne.nz/dashboard`}
                    >
                        <img src={LeaderboardSVG} />

                        <span>Leaderboard</span>
                    </a>
                </li>
                <li>
                    <a
                        href={`https://app.techne.nz/dashboard`}
                    >
                        <img src={ClassSVG} />

                        <span>Your Class</span>
                    </a>
                </li>
                <li>
                    <a
                        href="#"
                    >
                        <img src={BlockSVG} />

                        <span>Scratch</span>
                    </a>
                    
                </li>
                <li>
                    <a
                        href="#"
                    >
                        <img src={TextSVG} />

                        <span>Code Editor</span>
                    </a>
                </li>
                <li>
                    <a
                        href={`https://app.techne.nz/dashboard`}
                    >
                        <img src={KeyboardSVG} />

                        <span>Touch Typing</span>
                    </a>
                </li>
                <li className={styles.settingsIcon}>
                    <a
                        href={`https://app.techne.nz/dashboard`}
                    >
                        <img src={SettingsSVG} />

                        <span>Settings</span>
                    </a>
                </li>

                <li className={styles.logoutIcon}>
                    <a
                        href={`https://app.techne.nz/dashboard`}
                    >
                        <img src={LogoutSVG} />

                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
   
};

export default Navbar;
